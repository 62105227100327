import axios from 'axios';
import {urlApi} from "@email-manager/api";
import {getTemplateData} from "@email-manager/params";

// const API_UPLOAD_IMAGE = 'https://api.cloudinary.com/v1_1/dvsosdqqu/image/upload';
const API_UPLOAD_IMAGE = `${urlApi}/lcupload/load`;
const URL_BUCKET = 'https://leadcars.s3.eu-west-1.amazonaws.com/';

function getTemplateId() {
    return window.location.href.split('/').pop();
}

export const common = {
    async uploadByQiniu(file: File | Blob): Promise<string> {
        const data = new FormData();

        data.append('filepond', file);
        data.append('pathTmp', getTemplateId());
        const templateData = getTemplateData();
        const path = `templates/emails/${templateData.id_client}/${getTemplateId()}`;
        data.append('pathDestiny', path);
        const res = await axios.post<{ url: string }>(API_UPLOAD_IMAGE, data);

        return `${URL_BUCKET}${path}/${res.data}`;
    }
};

export interface IAppMenuItem {
    name: string;
    url?: string;
    icon: string;
    isOpen?: boolean;
    children: IAppSubMenuItem[];
}

export interface IAppSubMenuItem {
    name: string;
    url: string;
    isOpen?: boolean;
}
