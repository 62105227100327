export const urlApi =
  import.meta.env.VITE_LC_API_URL ?? "https://api.leadcars.es";

/**
 *
 * @param id
 * @returns {Promise<boolean|string>}
 */
export async function getTemplate(id) {
  console.log("CARGANDO DATOS DEL TEMPLATE");
  const request = await fetch(
    `${urlApi}/api/v2/email/builder/manager/id/${id}`
  );
  const response = await request.json();

  if (response && response?.template?.type == "advanced") {
    return response;
  }

  return false;
}

/**
 *
 * @param id
 * @param template
 * @returns {Promise<any>}
 */
export async function updateTemplate(id, template) {
  const request = await fetch(
    `${urlApi}/api/v2/email/builder/manager/update/${id}`,
    {
      method: "PUT",
      body: JSON.stringify({
        template,
      }),
      headers: {
        "content-type": "application/json",
      },
    }
  );

  const response = await request.json();

  return response;
}

/**
 *
 * @returns {Promise<any>}
 */
export async function getEmailParamsList(lang) {
  const url = `${urlApi}/api/v2/email/builder/list_parameters?group=emailManager&lang=${lang}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  });
  return await response.json();
}
