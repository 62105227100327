const sessionName = 'templateData';

export function getTemplateData() {
    const data = sessionStorage.getItem(sessionName);
    console.log(JSON.parse(data));
    return JSON.parse(data);
}

export function setTemplateData(data) {
    sessionStorage.setItem(sessionName, JSON.stringify(data));
}
