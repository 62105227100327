/* eslint-disable react/jsx-wrap-multilines */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, message, PageHeader} from 'antd';

import {EmailEditor, EmailEditorProvider, IEmailTemplate, Stack,} from 'easy-email-editor';
import 'easy-email-editor/lib/style.css';
import 'antd/dist/antd.css';
import {AdvancedType, BasicType, BlockManager, JsonToMjml} from 'easy-email-core';
import {ExtensionProps, MjmlToJson, StandardLayout} from 'easy-email-extensions';
import {FormApi} from 'final-form';
import 'easy-email-extensions/lib/style.css';
import '@arco-themes/react-easy-email-theme-purple/css/arco.css';
import './App.css';

import {useWindowSize} from 'react-use'

import './CustomBlocks';
import {useMergeTagsModal} from "@email-manager/components/useMergeTagsModal";
import {testMergeTags} from "@email-manager/testMergeTags";

import localesData from '@packages/easy-email-localization/locales/locales.json';
import {Select} from "@arco-design/web-react";
import {getEmailParamsList, getTemplate, updateTemplate} from "@email-manager/api";
import services from "@email-manager/services";
import {setTemplateData} from "@email-manager/params";
import {cloneDeep, set} from "lodash";

const imageCompression = import('browser-image-compression');

const fontList = [
    'Arial',
    'Tahoma',
    'Verdana',
    'Times New Roman',
    'Courier New',
    'Georgia',
    'Lato',
    'Montserrat',
].map(item => ({value: item, label: item}));

const pageBlock = BlockManager.getBlockByType(BasicType.PAGE)!;

function getTemplateId() {
    return window.location.href.split('/').pop();
}


export default function Editor() {

    const [locale, setLocale] = useState('es');

    const [downloadFileName, setDownloadName] = useState('download.mjml');
    const [template, setTemplate] = useState<IEmailTemplate['content']>(pageBlock.create({
        data: {
            value: {
                "content-background-color": '#ffffff'
            }
        }
    }));


    const categories: ExtensionProps['categories'] = [
        {
            label: localesData[locale]['Content'],
            active: true,
            blocks: [
                {
                    type: AdvancedType.TEXT,
                },
                {
                    type: AdvancedType.IMAGE,
                    payload: {attributes: {padding: '0px 0px 0px 0px'}},
                },
                {
                    type: AdvancedType.BUTTON,
                },
                {
                    type: AdvancedType.SOCIAL,
                },
                {
                    type: AdvancedType.DIVIDER,
                },
                {
                    type: AdvancedType.SPACER,
                },
                {
                    type: AdvancedType.HERO,
                },
                // {
                //     type: AdvancedType.ACCORDION,
                // },
                // {
                //     type: AdvancedType.CAROUSEL,
                // },

                {
                    type: AdvancedType.WRAPPER,
                },
            ],
        },
        {
            label: localesData[locale]['Layout'],
            active: true,
            displayType: 'column',
            blocks: [
                {
                    title: localesData[locale]['2 columns'], //
                    payload: [
                        ['50%', '50%'],
                        ['33%', '67%'],
                        ['67%', '33%'],
                        ['25%', '75%'],
                        ['75%', '25%'],
                    ],
                },
                {
                    title: localesData[locale]['3 columns'],
                    payload: [
                        ['33.33%', '33.33%', '33.33%'],
                        ['25%', '25%', '50%'],
                        ['50%', '25%', '25%'],
                    ],
                },
                {
                    title: localesData[locale]['4 columns'],
                    payload: [
                        ['25%', '25%', '25%', '25%']
                    ],
                },
            ],
        },
    ];

    // const [template, setTemplate] = useState<IEmailTemplate['content']>(templateData);

    useEffect(() => {
        /**
         * Comprobaciones e inicializaciones
         */
        const onReady = async () => {
            console.log('ONLOAD')
            const templateData = await getTemplate(getTemplateId());
            console.log(templateData);
            if (!templateData) {
                return;
            }
            setTemplateData(templateData);

            // Template
            const template = templateData.template.content;
            const json = MjmlToJson(template);
            setTemplate(json);

            // Variables
            const params = await getEmailParamsList(templateData.lang);
            setMergeTags(params);

            // Idioma
            setLocale(templateData.lang);
        }

        document.addEventListener("DOMContentLoaded", onReady);

    }, []);

    // const { importTemplate } = useImportTemplate();
    // const { exportTemplate } = useExportTemplate();

    const {width} = useWindowSize();

    const smallScene = width < 1400;

    /*  const onCopyHtml = (values: IEmailTemplate) => {
        const html = mjml(JsonToMjml({
          data: values.content,
          mode: 'production',
          context: values.content
        }), {
          beautify: true,
          validationLevel: 'soft',
        }).html;

        copy(html);
        message.success('Copied to pasteboard!')
      };

      const onImportMjml = async () => {
        try {
          const [filename, data] = await importTemplate();
          setDownloadName(filename);
          setTemplate(data);
        } catch (error) {
          message.error('Invalid mjml file');
        }
      };
*/
    /*     const onExportMjml = (values: IEmailTemplate) => {
           exportTemplate(
             downloadFileName,
             JsonToMjml({
               data: values.content,
               mode: 'production',
               context: values.content
             }))
         };*/

    const onSubmit = useCallback(
        async (
            values: IEmailTemplate,
            form: FormApi<IEmailTemplate, Partial<IEmailTemplate>>
        ) => {
            console.log('values', values)

            const mjml = JsonToMjml({
                data: values.content,
                mode: 'production',
                context: values.content
            });

            const template = {
                "type": "advanced",
                "content": mjml
            };

            updateTemplate(getTemplateId(), template).then(response => {
                // form.restart(newValues); replace new values form backend
                message.success(localesData[locale]['Saved success!']);

                window.close();
            });

        },
        []
    );


    const {
        modal: mergeTagsModal,
        openModal: openMergeTagsModal,
        mergeTags,
        setMergeTags,
    } = useMergeTagsModal(testMergeTags);

    const onUploadImage = async (blob: Blob) => {
        const compressionFile = await (
            await imageCompression
        ).default(blob as File, {
            maxWidthOrHeight: 1440,
        });
        return services.common.uploadByQiniu(compressionFile);
    };


    const onChangeMergeTag = useCallback((path: string, val: any) => {
        setMergeTags(old => {
            const newObj = cloneDeep(old);
            set(newObj, path, val);
            return newObj;
        });
    }, []);


    // TODO Precargar del JSON
    const initialValues: IEmailTemplate | null = useMemo(() => {
        return {
            subject: 'Asunto',
            subTitle: 'Subtitulo',
            content: template
        };
    }, [template]);

    const url = (window.location != window.parent.location)
        ? document.referrer
        : document.location.href;
    console.log(url);
    if(getTemplateId() === '' || url.includes('mailbuilder.leadcars.es') || url.includes('mailbuilderdev.leadcars.es')) {
        window.location.href = 'https://leadcars.es';
        return null;
    }
    if (!initialValues) return null;

    return (
        <div>
            <EmailEditorProvider
                dashed={false}
                data={initialValues}
                height={'calc(100vh - 85px)'}
                onUploadImage={onUploadImage}

                autoComplete
                fontList={fontList}
                onSubmit={onSubmit}

                onChangeMergeTag={onChangeMergeTag}
                mergeTags={mergeTags}
                mergeTagGenerate={tag => `{{${tag}}}`}

                locale={localesData[locale]}
            >
                {({values}, {submit}) => {
                    return (
                        <>
                            {/*                        <PageHeader
                            ghost={false}
                            // onBack={() => window.history.back()}
                            title={
                                <div
                                    style={{
                                        color: '#fff',
                                        fontWeight: 'bold',
                                    }}
                                    id="clientName"
                                >
                                </div>
                            }
                            // subTitle="This is a subtitle"
                            style={{
                                backgroundColor: '#2a415a',
                                color: '#fff',
                            }}
                            extra={[
                                // <Button key="3">Operation</Button>,
                                // <Button key="2">Operation</Button>,
                                // <Button key="1" type="primary">
                                //     <CloseOutlined />
                                // </Button>,
                                <Button
                                    type="primary"
                                    shape="round"
                                    icon={<CloseOutlined />}
                                    size="middle"
                                    onClick={() => window.close()}
                                >
                                    Cerrar
                                </Button>
                            ]}
                        >
                        </PageHeader>*/}
                            <PageHeader
                                title={localesData[locale]['Email manager']}
                                extra={
                                    <Stack alignment="center">

                                        <Select
                                            onChange={setLocale}
                                            value={locale}
                                            style={{display: "none"}}
                                        >
                                            <Select.Option value='es'>{localesData[locale]['Spanish']}</Select.Option>
                                            <Select.Option
                                                value='pt'>{localesData[locale]['Portuguese']}</Select.Option>
                                            <Select.Option value='it'>{localesData[locale]['Italian']}</Select.Option>
                                            <Select.Option value='en'>{localesData[locale]['English']}</Select.Option>

                                        </Select>
                                        {/*
                    <Button onClick={() => onCopyHtml(values)}>
                      Copy Html
                    </Button>
                    <Button onClick={() => onExportMjml(values)}>
                      Export Template
                    </Button>
                    <Button onClick={onImportMjml}>
                      import Template
                    </Button>*/}
                                        <Button
                                            type='primary'
                                            onClick={() => submit()}
                                        >
                                            {localesData[locale]['Save']}
                                        </Button>
                                    </Stack>
                                }
                            />

                            <StandardLayout
                                compact={!smallScene}
                                categories={categories}
                                showSourceCode={true}
                            >
                                <EmailEditor/>
                            </StandardLayout>
                        </>
                    );
                }}
            </EmailEditorProvider>
            {mergeTagsModal}
        </div>
    );
}
